import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Table,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { tableColumns, tableData } from "../tables/data";
import {
  faSortUp,
  faSortDown,
  faSort,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableOptions,
  useTable,
  useSortBy,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useNavigate } from "react-router-dom";

interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: Function;
}

// Define a default UI for filtering
function GlobalFilter(props: GlobalFilterProps) {
  const count = props.preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(props.globalFilter);
  const onChange = useAsyncDebounce((value) => {
    props.setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className="pb-4">
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
        style={{
          fontSize: "1.1rem",
        }}
      />
    </span>
  );
}

const ColumnSortingTable = (props: TableOptions<Record<string, unknown>>) => {
  const { columns, data } = props;
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <Card>
      <Card.Header>
        <Card.Title>Column Sorting</Card.Title>
        <Row>
          <Col>
            <span className="card-subtitle text-muted">
              Column sorting by react-table
            </span>
          </Col>
          <Col>
            <Button
              className="mb-4 end float-end float-middle float-top"
              onClick={() => {
                navigate("../edit");
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Table striped bordered {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} className="ms-2" />
                      )}
                    </span>
                  </th>
                ))}
                <th>Edit</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              console.log(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td>
                    <a
                      onClick={() => {
                        navigate(`../edit/${row.original.name}`);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} className="ms-2" />
                    </a>
                    <a href="">
                      <FontAwesomeIcon icon={faTrashAlt} className="ms-2" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Row>
          <Col md="6">
            <span className="mx-2">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span className="ms-3 me-2">Show:</span>
            <Form.Select
              className="d-inline-block w-auto"
              value={pageSize}
              onChange={(e: any) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md="6">
            <Pagination className="float-end">
              <Pagination.First
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              />
              <Pagination.Prev
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              />
              <Pagination.Next
                onClick={() => nextPage()}
                disabled={!canNextPage}
              />
              <Pagination.Last
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              />
            </Pagination>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const InvoiceList = () => {
  return (
    <React.Fragment>
      <Helmet title="Column Sorting" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Invoices</h1>

        <ColumnSortingTable
          columns={tableColumns}
          data={tableData.slice(0, 15)}
        />
      </Container>
    </React.Fragment>
  );
};

export default InvoiceList;
